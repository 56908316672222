import React from "react";
import { Routes, Route, Router } from "react-router-dom";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";

import Landing from "./pages/Landing";

import "./App.css";

let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ADD8E6",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#8496A6",
    },
    tertiary: {
      main: "#672490",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#092C4C",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h1: {
      fontWeight: 800,
      fontSize: "48px",
      color: "#FFFFFF",
    },
    h2: {
      fontWeight: 700,
      fontSize: "30px",
      color: "#FFFFFF",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "20px",
      color: "#FFFFFF",
    },
    body1: {
      fontWeight: 400,
      fontSize: "14px",
      color: "#FFFFFF",
    },
    hyperlinkButton: {
      fontWeight: 700,
      fontSize: "20px",
      color: "#FFFFFF",
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Landing />
      </ThemeProvider>
    </div>
  );
}

export default App;
