import React from "react";

import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Stack,
} from "@mui/material";

import bolt from "../assets/bolt.png";

function Landing() {
  return (
    <Container>
      <Grid border={"0px solid blue"} sx={{ py: 15 }}>
        <Grid border={"0px solid blue"} sx={{ my: 10 }}>
          <Typography variant="h1">Electrify Everything Australia</Typography>
          <Typography variant="subtitle1" color={"#ADD8E6"}>
            Coming soon
          </Typography>
        </Grid>

        <Typography variant="body1" color={"#ADD8E6"} sx={{ mt: 2, mb: 5 }}>
          Join the list to stay in the loop, and be notified when we launch!
        </Typography>
        <Grid
          container
          id="email-wrapper"
          direction="horizontal"
          justifyContent={"center"}
          spacing={2}
          sx={{ mb: 5 }}
        >
          <Grid item display={"flex"}>
            <Button
              href="mailto:hi@electrifyeverything.au"
              height="100%"
              variant="contained"
            >
              Contact
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <img src={bolt} height={"100px"} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Landing;
